<template>
  <div class="mfa-panel mx-auto mb-16">
    <v-row class="mt-6">
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp && !isSignin"
        fab
        text
        absolute
        plain
        left
        class="mt-n3 ml-5 my-auto"
        @click="$emit('updateStep', 4)"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
      <span class="text-h5 mx-auto grey-darken-4--text font-weight-bold">{{
        $t("verification")
      }}</span>
    </v-row>
    <v-row class="mt-4">
      <span class="mx-auto grey-darken-2--text font-weight-medium body-2">{{
        $t("enterVerificationCode")
      }}</span>
    </v-row>
    <v-row class="mt-10 text-center" justify="center">
      <v-col cols="8" xl="12" lg="12" md="12" class="pb-0" max-width="200">
        <span
          class="mx-auto grey-darken-2--text subtitle-2 font-weight-regular"
        > A confirmation code has been sent to <strong>{{user.email}}</strong>.</span>
      </v-col>
    </v-row>
    <v-row>
      <span class="mx-auto grey-darken-2--text subtitle-2 font-weight-regular">
        {{ $t("checkSpamMsg") }}
      </span>
    </v-row>
    <v-row class="mt-6">
      <v-col class="mx-auto" cols="9" xl="12" sm="9" md="9" lg="9" space-around>
        <label aria-label="Enter MFA"
          ><v-otp-input
            v-model="otp"
            type="number"
            length="4"
            :class="{ 'error-state': isVerificationFailed }"
            @input="onOtpChange"
          ></v-otp-input
        ></label>

        <div v-if="isVerificationFailed" class="text-center red--text caption">
          {{ $t("verificationFailedMsg") }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" lg="12" md="12" class="text-center">
        <span class="grey-darken-4--text font-weight-regular body-2">
          {{ $t("notReceivedCode") }}
        </span>
        <v-btn
          class="text-none pr-0 pl-0"
          color="primary"
          text
          @click="resendMFA"
        >
          Resend
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" lg="6" md="6" class="mx-auto mb-2">
        <v-btn
          block
          class="mr-4 text-none rounded-lg"
          color="primary"
          :disabled="!isValidOtp"
          @click="verifyMFA"
        >
          {{ $t("verify") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { setAuthStatus, setAuthDetails } from "@/store/utils/utils";
export default {
  name: "MFA",
  props: {
    isSignin: {
      type: Boolean,
      default: false,
    },
    password: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    otp: null,
    isOtpSent: false,
    isVerificationFailed: false,
  }),

  computed: {
    user() {
      return this.$store.getters.userData;
    },
    isValidOtp() {
      return this.otp && this.otp.length === 4;
    },
  },

  methods: {
    onOtpChange() {
      this.isVerificationFailed = false;
    },
    async resendMFA() {
      if (this.isSignin) {
        const varificationStatus = await this.$store.dispatch("authorizeUser", {
          password: this.password,
        });
        if (varificationStatus.message == "MFA code sent") {
          this.$emit("mfaCodeSent");
        }
      } else {
        const resp = await this.$store.dispatch("resendMFACode");
        if (resp.user_id) {
          this.$emit("mfaCodeSent");
        }
      }
    },
    async verifyMFA() {
      const authObject = {};
      console.log(this.isSignin);
      if (this.isSignin) {
        authObject.password = this.password;
        authObject.mfacode = this.otp;
      } else {
        authObject.password = this.otp;
        authObject.mfacode = "";
      }
      const varificationStatus = await this.$store.dispatch(
        "authorizeUser",
        authObject,
      );
      if (
        varificationStatus.status === "error" &&
        (varificationStatus.error === "Invalid password" ||
          varificationStatus.error === "Invalid mfa code" ||
          varificationStatus.error === "Invalid user")
      ) {
        this.isVerificationFailed = true;
      } else {
        if (this.isSignin) {
          setAuthDetails({
            userId: varificationStatus.user_id,
            token: varificationStatus.token,
          });
        }
        this.$emit("userVerified");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/settings.scss";
.mfa-panel {
  max-width: 350px;
  .error-state {
    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
      > .v-input__control
      > .v-input__slot
      fieldset {
      color: $red;
    }
  }
}
</style>
