var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mfa-panel mx-auto mb-16" },
    [
      _c(
        "v-row",
        { staticClass: "mt-6" },
        [
          _vm.$vuetify.breakpoint.mdAndUp && !_vm.isSignin
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-n3 ml-5 my-auto",
                  attrs: {
                    fab: "",
                    text: "",
                    absolute: "",
                    plain: "",
                    left: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("updateStep", 4)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass:
                "text-h5 mx-auto grey-darken-4--text font-weight-bold",
            },
            [_vm._v(_vm._s(_vm.$t("verification")))]
          ),
        ],
        1
      ),
      _c("v-row", { staticClass: "mt-4" }, [
        _c(
          "span",
          {
            staticClass:
              "mx-auto grey-darken-2--text font-weight-medium body-2",
          },
          [_vm._v(_vm._s(_vm.$t("enterVerificationCode")))]
        ),
      ]),
      _c(
        "v-row",
        { staticClass: "mt-10 text-center", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              attrs: {
                cols: "8",
                xl: "12",
                lg: "12",
                md: "12",
                "max-width": "200",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "mx-auto grey-darken-2--text subtitle-2 font-weight-regular",
                },
                [
                  _vm._v(" A confirmation code has been sent to "),
                  _c("strong", [_vm._v(_vm._s(_vm.user.email))]),
                  _vm._v("."),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c("v-row", [
        _c(
          "span",
          {
            staticClass:
              "mx-auto grey-darken-2--text subtitle-2 font-weight-regular",
          },
          [_vm._v(" " + _vm._s(_vm.$t("checkSpamMsg")) + " ")]
        ),
      ]),
      _c(
        "v-row",
        { staticClass: "mt-6" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                cols: "9",
                xl: "12",
                sm: "9",
                md: "9",
                lg: "9",
                "space-around": "",
              },
            },
            [
              _c(
                "label",
                { attrs: { "aria-label": "Enter MFA" } },
                [
                  _c("v-otp-input", {
                    class: { "error-state": _vm.isVerificationFailed },
                    attrs: { type: "number", length: "4" },
                    on: { input: _vm.onOtpChange },
                    model: {
                      value: _vm.otp,
                      callback: function ($$v) {
                        _vm.otp = $$v
                      },
                      expression: "otp",
                    },
                  }),
                ],
                1
              ),
              _vm.isVerificationFailed
                ? _c("div", { staticClass: "text-center red--text caption" }, [
                    _vm._v(" " + _vm._s(_vm.$t("verificationFailedMsg")) + " "),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", sm: "12", lg: "12", md: "12" },
            },
            [
              _c(
                "span",
                {
                  staticClass: "grey-darken-4--text font-weight-regular body-2",
                },
                [_vm._v(" " + _vm._s(_vm.$t("notReceivedCode")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-none pr-0 pl-0",
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.resendMFA },
                },
                [_vm._v(" Resend ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto mb-2",
              attrs: { cols: "12", sm: "12", lg: "6", md: "6" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4 text-none rounded-lg",
                  attrs: {
                    block: "",
                    color: "primary",
                    disabled: !_vm.isValidOtp,
                  },
                  on: { click: _vm.verifyMFA },
                },
                [_vm._v(" " + _vm._s(_vm.$t("verify")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }